.v-select-blocks .vs__dropdown-toggle {
  height: auto;
  display: block;
}
.v-select-blocks .vs__dropdown-toggle .vs__selected-options {
  display: block;
  align-content: center;
}
.v-select-blocks .vs__dropdown-toggle .vs__selected-options .vs__selected {
  display: block;
}
.beforeUpload .icon {
  width: 50px !important;
}